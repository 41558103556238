<template lang="">
  <div class="box has-text-left" >
    <div class="">
      <span class="tag">
        {{ abilityLevel  ? "(" + abilityLevel  + ")" : "" }}
        [{{ (isActive) ? "Active":"Passive"}}]
      </span>
      <span class="">{{name}}</span>
      <span class="">
        <span v-for="tag in tagsArray" :key="tag" class="tag is-primary is-light">({{tag}})</span>
      </span>
    </div>
    <div v-if="isActive">
      <div>
        <span>Cost: </span>
        <span v-if="apcost" class="mx-1">{{apcost}} Action{{apcost > 1 ? 's':''}}</span>
        <span v-if="manacost" class="mx-1">{{manacost}} Mana</span>
        <span v-if="altresource" class="mx-1">{{altcost}} {{altresource}}</span>
      </div>
      <div>
        <span>Range: {{range}} <span v-if="range == 'Spell'">{{rangevalue}} ft</span></span>
      </div>
      <div>
        <span v-if="target">Target: {{target}}</span>
      </div>
      <div>
        <span v-if="duration">Duration: {{duration}}</span>
      </div>
      <div>
        <span v-if="resistance">Resistance: {{resistance}}</span>
      </div>
    </div>
    <div>
      <span>{{description}}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    name: String,
    isActive: Boolean,
    description: String,
    tags: String,
    apcost: Number,
    manacost: Number,
    altresource: String,
    altcost: Number,
    range: String,
    rangevalue: Number,
    target: String,
    duration: String,
    resistance: String,
    uid: String,
    createdAt: Object,
    lastModified: Object,
    docid: {type:String, default:null},
    abilityLevel:null,
    classification:null,
  },
  emits:[],
  methods: {

  },
  computed: {
    tagsArray() {
      return this.tags ? this.tags.split(',') : [];
    }
  }
}
</script>
<style lang="">
    
</style>