<template lang="">
  <div>
    <div class="title is-6">Offense</div>
    <div>
      <table class="table is-striped is-bordered">
        <thead>
          <tr>
            <th>Level (Tier)</th>
            <th v-for="tier in levelTierArray" :key="tier">{{ tier.level }} ({{ tier.tier }})</th>
            <th>ArPen</th>
            <th>Damage</th>
            <th>Crit</th>
            <th>Notes</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Accuracy Rating</th>
            <td v-for="tier in levelTierArray" :key="tier">{{ scaledAccuracy(tier.level) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>&nbsp;</th>
            <td v-for="tier in levelTierArray" :key="tier"></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>Max Mana</th>
            <td v-for="tier in levelTierArray" :key="tier">{{ scaledMana(tier.level) }}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props:{
        levelTierArray: Array,
        prowess: Number,
        spirit: Number,
        accuracy: String,
  },
  methods: {
    scaledAccuracy(curLevel) {
      if(isNaN(curLevel) || isNaN(this.prowess) || !this.accuracy) {
        return "NEED LEVEL/PROWESS/ACCURACY TO CALC THIS PROPERTY"
      }
      const accTable = {
        "Low" : 0.4,
        "Medium" : 0.6,
        "High" : 0.8
      };
      curLevel = parseInt(curLevel);
      const prowess = parseInt(this.prowess);
      return Math.round(prowess + (curLevel * accTable[this.accuracy]));
    },
    scaledMana(curLevel) {
      if(isNaN(curLevel) || isNaN(this.spirit)) {
        return "NEED LEVEL/SPIRIT TO CALC THIS PROPERTY"
      }
      curLevel = parseInt(curLevel);
      const spirit = parseInt(this.spirit);
      return spirit == 0 ? 0 : spirit + curLevel;
    }
  },
};
</script>

<style lang="">
</style>