<template lang="">
  <div>
    <div class="title is-6">Defense</div>
    <table class="table is-striped is-bordered">
      <thead>
        <tr>
          <th>Level (Tier)</th>
          <th v-for="tier in levelTierArray" :key="tier">{{ tier.level }} ({{ tier.tier }})</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>Hit Points</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledHp(tier.level) }}</td>
        </tr>
        <tr>
          <th>Evasion Rating</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledEvasion(tier.tier) }}</td>
        </tr>
        <tr>
          <th>Body Resistance</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledResist(body,tier.level) }}</td>
        </tr>
        <tr>
          <th>Agility Resistance</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledResist(agility,tier.level) }}</td>
        </tr>
        <tr>
          <th>Prowess Resistance</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledResist(prowess,tier.level) }}</td>
        </tr>
        <tr>
          <th>Spirit Resistance</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledResist(spirit,tier.level) }}</td>
        </tr>
        <tr>
          <th>Natural Armour</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledNatArmor(tier.tier) }}</td>
        </tr>
        <tr v-for="(armorValue, armorName) in specificArmors" :key="armorName">
          <th>{{ specificArmorNameAbbrev(armorName) }}</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledArmor(tier.tier, armorValue) }}</td>
        </tr>
        <tr v-for="(armorValue, armorName) in specialArmors" :key="armorName">
          <th>{{ armorName }}</th>
          <td v-for="tier in levelTierArray" :key="tier">{{ scaledArmor(tier.tier, armorValue) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  props:{
        levelTierArray: Array,
        hp: String,
        body: Number,
        agility: Number,
        prowess: Number,
        spirit: Number,
        natArmor: String,
        resistance: String,
        specificArmors: {type:Object,default:function(){return{}}},
        specialArmors: {type:Object,default:function(){return{}}},
  },
  methods: {
    scaledHp(curLevel) {
      if(isNaN(curLevel) || isNaN(this.body)) {
        return "NEED LEVEL/BODY TO CALC THIS PROPERTY";
      }
      const hpTable = {
        "Lowest": {"base": 4, "coeff":4},
        "Low": {"base": 8, "coeff":6},
        "Average": {"base": 12, "coeff":8},
        "High": {"base": 16, "coeff":10},
        "Highest": {"base": 20, "coeff":12},
      }
      curLevel = parseInt(curLevel);
      const body = parseInt(this.body);
      return hpTable[this.hp].base + (curLevel * (body + hpTable[this.hp].coeff));
    },
    scaledEvasion(curTier) {
      if(!curTier || isNaN(this.agility)) {
        return "NEED LEVEL/AGILITY TO CALC THIS PROPERTY"
      }
      const agi = parseInt(this.agility);
      curTier = parseInt(curTier);
      return 10 + agi + curTier * 2;
    },
    scaledResist(resistValue, curLevel) {
      if(isNaN(resistValue) || isNaN(curLevel)) {
        return "NEED LEVEL/RESISTANCE TO CALC THIS PROPERTY";
      }
      const resTable = {
        "Low" : 0.4,
        "Medium" : 0.6,
        "High" : 0.8
      };
      const rValue = parseInt(resistValue);
      curLevel = parseInt(curLevel);
      return Math.round(rValue + (resTable[this.resistance] * curLevel));
    },
    scaledNatArmor(curTier) {
      if(!curTier || !this.natArmor) {
        return "NEED LEVEL/NATARMOR TO CALC THIS PROPERTY";
      }
      const natTable = {
        "Lowest": [4,7,10,13],
        "Low": [5,9,12,16],
        "Average": [7,11,15,19],
        "High": [8,13,17,22],
        "Highest": [10,15,20,25],
      };
      curTier = parseInt(curTier);
      return natTable[this.natArmor][curTier - 1];
    },
    specificArmorNameAbbrev(armorName) {
      if(!armorName) {
        return "COULDNT FIND ARMOR";
      }
      const saTable = {
        "Slashing": "PA (Slashing)",
        "Impact":"PA (Impact)",
        "Piercing":"PA (Piercing)",
        "Explosive":"PA (Explosive)",
        "Fire":"EA (Fire)",
        "Frost":"EA (Frost)",
        "Electric":"EA (Electric)",
        "Corrosive":"EA (Corrosive)",
        "Radiant":"AA (Radiant)",
        "Necrotic":"AA (Necrotic)",
        "Force":"AA (Force)",
        "Chaos":"AA (Chaos)",
      };
      return saTable[armorName];
    },
    scaledArmor(curTier, saVal) {
       if(isNaN(curTier) || isNaN(saVal)) {
        return "NEED LEVEL/SCALED ARMOR TO CALC THIS PROPERTY"
      }
      return parseInt(curTier) * parseInt(saVal);
    },
  },
};
</script>

<style lang="">
</style>