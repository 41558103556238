<template lang="">
    <div>
        <div v-if="creatures">
          <Creature v-for="creature of creatures" :="creature" :key="creature" @deleted="reloadCreatures()" />
        </div>
    </div>
</template>
<script>
import Creature from "@/components/creature/Creature";
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    Creature,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'creatures'
    ])
  },
  created() {
    if(!this.creatures) {
      this.setBusy();
      this.fetchCreatures().then(() => {
        this.setNotBusy();
      });

    }
  },
  methods: {
    ...mapActions([
      'fetchCreatures',
      'setBusy',
      'setNotBusy'
    ]),
    reloadCreatures() {
      this.setBusy();
      this.fetchCreatures().then(() => {
        this.setNotBusy();
      });
    }
  },

};
</script>
<style lang="">
</style>