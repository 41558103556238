<template lang="">
<div class="card my-4">
  <div class="card-header-title">{{ name }}</div>
  <div class="card-content  has-text-left">
    <div>
      {{race}} {{ (secrace && secrace != "None") ? secrace : ""}} -
      EQ {{ eq ? roundToFixedPoint(eq,1) : "" }} - Level {{ level }}
    </div>
    <div>
      Body: {{ body }} - Agility: {{ agility }} - Prowess: {{ prowess }} - Spirit: {{ spirit }}
    </div>
    <div>
      Size: {{ size }}
    </div>
    <div>
      Vision: {{ vision }}
      {{ visionArray.length > 0 ? " - Senses:" : "" }}
      {{ visionArray.length > 0 ? visionArray.join(", ") : ""}}
    </div>
    <div>
      Movement Speed: {{movement}}' {{ altMoves.size }}
      {{ Object.keys(altMoves).length > 0 ? " - " + altMovesAsList : "" }}
    </div>
    <DefenseTable 
      :agility="agility"
      :body="body"
      :spirit="spirit"
      :prowess="prowess"
      :levelTierArray="levelTierArray"
      :hp="hp"
      :natArmor="natArmor"
      :resistance="resistance"
      :specificArmors="specificArmors"
      :specialArmors="specialArmors"
      >
    </DefenseTable>
    <div>
      Immunities: {{ immunities }}
    </div>
    <div>
      Vulnerabilites: {{ vulnerabilities}}
    </div>
    <OffenseTable 
      :spirit="spirit"
      :prowess="prowess"
      :accuracy="accuracy"
      :levelTierArray="levelTierArray"
      >
    </OffenseTable>
    <div>
      Skills: {{ arrayOfStringsWithCommas(skillArray) }}
    </div>
    <div>
      Treasure Profile: {{ treasure }}
    </div>
    <div>
      Threat Profile: {{ threat }}
    </div>
    <div>
      {{ flavour }}
    </div>
    <div>
      History: {{ history }}
    </div>
    <div>
      Tactics: {{ tactics }}
    </div>
    <div>
      <div v-if="fullAbilityObjects">
        <ability-view v-if="fullAbilityObjects.racial" v-bind="fullAbilityObjects.racial"   abilityLevel="Racial"></ability-view>
        <ability-view v-if="fullAbilityObjects.base" v-bind="fullAbilityObjects.base"     abilityLevel="Base"></ability-view>
        <ability-view v-if="fullAbilityObjects.t1" v-bind="fullAbilityObjects.t1"       abilityLevel="Tier 1"></ability-view>
        <ability-view v-if="fullAbilityObjects.t2" v-bind="fullAbilityObjects.t2"       abilityLevel="Tier 2"></ability-view>
        <ability-view v-if="fullAbilityObjects.t3" v-bind="fullAbilityObjects.t3"       abilityLevel="Tier 3"></ability-view>
        <ability-view v-if="fullAbilityObjects.t4" v-bind="fullAbilityObjects.t4"       abilityLevel="Tier 4"></ability-view>
        <ability-view v-if="fullAbilityObjects.boss" v-bind="fullAbilityObjects.boss"     abilityLevel="Boss"></ability-view>
        <ability-view v-if="fullAbilityObjects.hardcore" v-bind="fullAbilityObjects.hardcore" abilityLevel="Hardcore"></ability-view>
      </div>
    </div>
  </div>
  <div>
    <button class="button" @click="deleteCreature">Delete</button>
    <button class="button" @click="editCreature">Edit</button>
    <button class="button" @click="duplicateCreature">Duplicate</button>
  </div>
</div>
</template>

<script>
import AbilityView from '@/components/AbilityView'
import DefenseTable from '@/components/creature/DefenseTable'
import OffenseTable from '@/components/creature/OffenseTable'
import { mapGetters, mapActions } from "vuex";
import router from "@/router";

export default {
  data() {
    return {
      fullAbilityObjects: null,
    };
  },
  components: {
    AbilityView,
    DefenseTable,
    OffenseTable,
  },
  emits:['deleted','duplicated'],
  props:{
        name: String,
        race: String,
        secrace: String,
        eq: Number,
        level: Number,
        body: Number,
        agility: Number,
        prowess: Number,
        spirit: Number,
        vision: Number,
        size: String,
        visionArray: {type:Array,default:()=>[]},
        hp: String,
        accuracy: String,
        movement: Number,
        altMoves: {type:Object,default:function(){return{}}},
        resistance: String,
        natArmor: String,
        specificArmors: {type:Object,default:function(){return{}}},
        specialArmors: {type:Object,default:function(){return{}}},
        immunities: String,
        vulnerabilities: String,
        skillArray: {type:Array,default:()=>[]},
        threat: String,
        treasure: String,
        flavour: String,
        history: String,
        tactics: String,
        racialAbility: String,
        baseAbility: String,
        t1Ability: String,
        t2Ability: String,
        t3Ability: String,
        t4Ability: String,
        bossAbility: String,
        hardcoreAbility: String,
        createdAt: Object,
        lastModified: Object,
        uid: String,
        docid: String,
  },
  methods: {
    ...mapActions([
      'fetchAbilities',
      'deleteCreatureById',
      'duplicateCreatureById',
      'setBusy',
      'setNotBusy',
    ]),
    deleteCreature() {
      this.setBusy();
      this.deleteCreatureById(this.docid).then(() => {
        this.setNotBusy();
        this.$emit('deleted');
      });
    },
    editCreature() {
      router.push({name:'CreatureEdit',params: {docid:this.docid}});
    },
    duplicateCreature() {
      this.setBusy();
      this.duplicateCreatureById(this.docid).then((newDoc) => {
        this.setNotBusy();
        router.push({name:"CreatureEdit",params:{docid:newDoc.docid}});
      });
    },
  },
  computed: {
    ...mapGetters([
      'fbTimestamp',
      'user',
      'getAbilityByName',
    ]),
    altMovesAsList() {
      let str = "";
      for(const [key, value] of Object.entries(this.altMoves)) {
        str += key + " " + value + "', ";
      }
      return str.substring(0,str.length - 2);
    },
    tier() {
      if(isNaN(this.level)) {
        return "LEVEL IS NOT A NUMBER";
      }
      const levelAsFloat = parseFloat(this.level);
      if(levelAsFloat > 20 ) {
        return "LEVEL GREATER THAN 20 WTF";
      } else if(levelAsFloat <= 0 ) {
        return "LEVEL IS LESS THAN ZERO WTF";
      } else {
        return Math.ceil(levelAsFloat/5);
      } 
    },
    levelTierArray() {
      const tiermap = [];
      for(let i = parseInt(this.level); i <= 20; i += 5 ) {
        tiermap.push({"level" : i, "tier":Math.ceil(i/5)});
      }
      return tiermap;
    },
  },
  created() {
    let loadPromises = [];
    if(!this.abilities) {
      loadPromises.push(this.fetchAbilities());
    }
    Promise.all(loadPromises).then(() => {
      this.fullAbilityObjects = {
        'racial': this.getAbilityByName(this.racialAbility),
        'base': this.getAbilityByName(this.baseAbility),
        't1': this.getAbilityByName(this.t1Ability),
        't2': this.getAbilityByName(this.t2Ability),
        't3': this.getAbilityByName(this.t3Ability),
        't4': this.getAbilityByName(this.t4Ability),
        'boss': this.getAbilityByName(this.bossAbility),
        'hardcore': this.getAbilityByName(this.hardcoreAbility),
      };
    })
  },
};
</script>

<style lang="">
</style>